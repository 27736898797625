<template>
  <v-container fluid>
    <TheTitle :text="title" :icon="pageIcon">
      <v-row justify="center">
        <v-col md="8" sm="10" cols="10">
          <v-text-field
            placeholder="Procurar"
            dense
            solo
            background-color="#FFFFFF"
            hide-details
            v-model="search"
          ></v-text-field>
        </v-col>
        <v-col md="4" sm="4" cols="4">
          <v-btn block color="amber darken-4" dark @click="dialogSave()">
            <v-icon small class="mr-2">mdi-plus</v-icon> Adicionar</v-btn
          >
        </v-col>
      </v-row>
    </TheTitle>

    <v-card class="pa-5 sd-lt rd-lt full-height">
      <v-data-table
        :headers="header"
        :items="objects"
        dense
        locale="pt-BR"
        :search="search"
        :items-per-page="30"
      >
        <template v-slot:[`item.action`]="{ item }">
          <TheToolTip label="Editar">
            <v-btn icon small tile>
              <v-icon @click="dialogUpdate(item)">mdi-pen</v-icon>
            </v-btn>
          </TheToolTip>
          <TheToolTip label="Remover">
            <v-btn icon small tile>
              <v-icon @click="remove(item.id)">mdi-minus</v-icon>
            </v-btn>
          </TheToolTip>
        </template>
      </v-data-table>
    </v-card>



    <TheDialog v-model="dialog" :title="title" :icon="pageIcon" width="1200">
      <template>
        <v-form v-model="valid" ref="form1">
          <v-tabs v-model="tabs" show-arrows>
            <v-tab>Sistema</v-tab>
            <v-tab>Repositório</v-tab>
            <v-tab>Backup</v-tab>
            <v-tab>Acessos</v-tab>
            <v-tab>Externo</v-tab>
            <!-- <v-tab>Responsáveis</v-tab> -->
            <v-tab>Logs e Outros</v-tab>
          </v-tabs>
          <v-divider class="my-5" />

          <v-tabs-items v-model="tabs">
            <v-tab-item>
              <v-row>
                <v-col md="6" sm="12" cols="12">
                  <b>Nome do Software<sup>*</sup></b>
                  <v-text-field
                    class="mt-2"
                    placeholder="Nome"
                    v-model="object.name"
                    dense
                    outlined
                    :rules="[requiredRule, spaceRule]"
                  ></v-text-field>
                </v-col>
                <v-col md="6" sm="12" cols="12">
                  <b>Fabricante do Software<sup>*</sup></b>
                  <v-text-field
                    class="mt-2"
                    placeholder="Fabricante"
                    v-model="object.manufacturer"
                    dense
                    outlined
                    :rules="[requiredRule, spaceRule]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="6" sm="12" cols="12">
                  <b>Módulos</b>
                  <v-text-field
                    class="mt-2"
                    placeholder="Módulos"
                    v-model="object.modules"
                    dense
                    outlined
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col md="6" sm="12" cols="12">
                  <p class="mb-2"><b>Forma de acesso</b></p>
                  <v-select
                    :items="access"
                    label="Selecione"
                    v-model="object.access"
                    solo
                    flat
                    outlined
                    dense
                  ></v-select>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item>
              <v-row>
                <v-col md="4" sm="12" cols="12">
                  <b>Reposistorio dos Dados do Sistema<sup>*</sup></b>
                  <v-select
                    class="my-2"
                    :items="place"
                    item-text="name" 
                    item-value="id"
                    v-model="object.storageId"
                    label="Selecione"
                    solo
                    flat
                    outlined
                    dense
                    :rules="[requiredRule, spaceRule]"
                  ></v-select>

                </v-col>
                <v-col md="4" sm="12" cols="12">
                  <b>Empresa responsável pela segurança dos dados</b>
                  <v-text-field
                    class="my-2"
                    placeholder="Nome"
                    v-model="object.storageCompanySecurity"
                    dense
                    outlined
                    :rules="[spaceRule]"
                  ></v-text-field>
                </v-col>
                <v-col md="4" sm="12" cols="12">
                  <b>Banco de Dados do Sistema</b>
                  <v-text-field
                    class="my-2"
                    placeholder="Banco"
                    v-model="object.storageSystem"
                    dense
                    outlined
                    :rules="[spaceRule]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="6" sm="12" cols="12">
                  <b>Outros Sistemas com Acesso</b>
                  <v-text-field
                    class="my-2"
                    placeholder="Nome"
                    v-model="object.storageAlternative"
                    dense
                    outlined
                    :rules="[spaceRule]"
                  ></v-text-field>
                </v-col>
                <v-col md="6" sm="12" cols="12">
                  <p class="mb-2"><b>Tipo do servidor</b></p>
                  <v-select
                    :items="serverTypes"
                    v-model="object.serverType"
                    label="Selecione"
                    solo
                    flat
                    outlined
                    dense
                  ></v-select>
                </v-col>
              </v-row>
              <!-- <v-col> -->
              <v-checkbox
                v-model="object.storagePhysical"
                label="Armazenamento Físico?"
              ></v-checkbox>
              <!-- </v-col> -->
              <!-- <v-col> -->
              <v-checkbox
                v-model="object.storageShare"
                label="Compartilhamento à terceiros?"
              ></v-checkbox>
              <!-- </v-col> -->
            </v-tab-item>

            <v-tab-item>
              <v-row>
                <v-col>
                  <b>Frequência de Backup</b>
                  <v-text-field
                    class="my-2"
                    placeholder="Frequência"
                    v-model="object.backupTime"
                    dense
                    outlined
                    :rules="[spaceRule]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="6" sm="12" cols="12">
                  <b>Repositório dos Backups</b>
                  <v-text-field
                    class="my-2"
                    placeholder="Repositório"
                    v-model="object.backupStorage"
                    dense
                    outlined
                    :rules="[spaceRule]"
                  ></v-text-field>
                </v-col>
                <v-col md="6" sm="12" cols="12">
                  <b>Responsável pelo Backup</b>
                  <v-text-field
                    class="my-2"
                    placeholder="Responsável"
                    v-model="object.backupResponsible"
                    dense
                    outlined
                    :rules="[spaceRule]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item>
              <!-- referente à acessos -->
              <v-row>
                <v-col md="6" sm="12" cols="12">
                  <p class="mb-2"><b>Setor responsável por cadastros</b></p>
                  <v-select
                    :items="sectors"
                    v-model="object.registrationSector"
                    dense
                    label="Selecione o setor"
                    hide-details
                    outlined
                    clearable
                    item-value="id"
                    item-text="name"
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index === 0" color="primary" dark small>{{
                        item.name
                      }}</v-chip>
                      <v-chip v-if="index === 1" color="gray" small
                        >+{{ object.registrationSector.length - 1 }}</v-chip
                      >
                    </template>
                  </v-select>
                </v-col>
                <v-col md="6" sm="12" cols="12"
                  ><p class="mb-2"><b>Pessoas responsáveis por cadastros</b></p>
                  <v-select
                    :items="people"
                    v-model="object.registrationPeople"
                    dense
                    label="Selecione as pessoas"
                    hide-details
                    multiple
                    outlined
                    clearable
                    item-value="id"
                    item-text="name"
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index === 0" color="primary" dark small>{{
                        item.name
                      }}</v-chip>
                      <v-chip v-if="index === 1" color="gray" small
                        >+{{ object.registrationPeople.length - 1 }}</v-chip
                      >
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="6" sm="12" cols="12">
                  <p class="mb-2"><b>Setor responsável por consultas</b></p>
                  <v-select
                    :items="sectors"
                    value="item.id"
                    v-model="object.querySector"
                    dense
                    label="Selecione as pessoas"
                    hide-details
                    outlined
                    clearable
                    item-value="id"
                    item-text="name"
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index === 0" color="primary" dark small>{{
                        item.name
                      }}</v-chip>
                      <v-chip v-if="index === 1" color="gray" small
                        >+{{ object.querySector.length - 1 }}</v-chip
                      >
                    </template>
                  </v-select>
                </v-col>
                <v-col md="6" sm="12" cols="12">
                  <p class="mb-2"><b>Pessoas responsáveis por consultas</b></p>
                  <v-select
                    :items="people"
                    value="item.id"
                    v-model="object.queryPeople"
                    dense
                    label="Selecione as pessoas"
                    hide-details
                    multiple
                    outlined
                    clearable
                    item-value="id"
                    item-text="name"
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index === 0" color="primary" dark small>{{
                        item.name
                      }}</v-chip>
                      <v-chip v-if="index === 1" color="gray" small
                        >+{{ object.queryPeople.length - 1 }}</v-chip
                      >
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="6" sm="12" cols="12">
                  <p class="mb-2">
                    <b>Setores que possuem acesso com direito administrativo</b>
                  </p>
                  <v-select
                    :items="sectors"
                    value="item.id"
                    v-model="object.administratorSector"
                    dense
                    label="Selecione as pessoas"
                    hide-details
                    multiple
                    outlined
                    clearable
                    item-value="id"
                    item-text="name"
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index === 0" color="primary" dark small>{{
                        item.name
                      }}</v-chip>
                      <v-chip v-if="index === 1" color="gray" small
                        >+{{ object.administratorSector.length - 1 }}</v-chip
                      >
                    </template>
                  </v-select>
                </v-col>
                <v-col md="6" sm="12" cols="12">
                  <p class="mb-2">
                    <b>Pessoas que possuem acesso com direito administrativo</b>
                  </p>
                  <v-select
                    :items="people"
                    value="item.id"
                    v-model="object.administratorPeople"
                    dense
                    label="Selecione as pessoas"
                    hide-details
                    multiple
                    outlined
                    clearable
                    item-value="id"
                    item-text="name"
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index === 0" color="primary" dark small>{{
                        item.name
                      }}</v-chip>
                      <v-chip v-if="index === 1" color="gray" small
                        >+{{ object.administratorPeople.length - 1 }}</v-chip
                      >
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item>
              <v-row>
                <v-col md="6" sm="12" cols="12">
                  <v-checkbox
                    v-model="object.accessExternal"
                    label="Acesso fora da Empresa?"
                  ></v-checkbox>
                </v-col>
                <v-col md="6" sm="12" cols="12">
                  <v-checkbox
                    v-model="object.accessExternalUser"
                    label="Acesso de Terceiros?"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row v-if="object.accessExternal">
                <v-col>
                  <b>Meios de Acessos</b>
                  <v-text-field
                    class="my-2"
                    placeholder="Meios de Acessos"
                    v-model="object.accessWay"
                    dense
                    outlined
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-tab-item>
            <!-- <v-tab-item>
              Responsáveis
            </v-tab-item> -->
            <v-tab-item>
              <v-row>
                <v-col md="6" sm="12" cols="12">
                  <p class="mb-2"><b>Possui segmentação por usuário?</b></p>
                  <v-select
                    :items="yesOrNo"
                    v-model="object.hasSegmentation"
                    label="selecione"
                    solo
                    flat
                    dense
                    outlined
                  ></v-select>
                </v-col>
                <v-col md="6" sm="12" cols="12">
                  <p class="mb-2">
                    <b
                      >Utiliza logs de acessos e/ou registro de alterações dos
                      dados?</b
                    >
                  </p>
                  <v-select
                    :items="yesOrNo"
                    v-model="object.hasLogs"
                    label="selecione"
                    solo
                    flat
                    dense
                    outlined
                  ></v-select>
                </v-col>
              </v-row>
              <b>Observações</b>
              <v-textarea outlined v-model="object.description"></v-textarea>
            </v-tab-item>
          </v-tabs-items>
        </v-form>
      </template>
      <template v-slot:actions="">
        <v-btn
          small
          depressed
          color="primary"
          class="mt-2"
          dark
          @click="resolveForm()"
        >
          <v-icon small class="mr-2">mdi-plus</v-icon>
          <span>Salvar</span>
        </v-btn>
        <v-spacer></v-spacer>
        <span class="ml-5 caption"><sup>*</sup>Campos obrigatórios</span>
      </template>
    </TheDialog>
  </v-container>
</template>

<script>
import axios from "axios"
import { baseApiUrl, showError } from "@/global"
import TheTitle from "@/components/TheTitle"
import TheDialog from "@/components/TheDialog"
import TheToolTip from "@/components/TheToolTip"

export default {
  name: "Dados",
  components: {
    TheTitle,
    TheDialog,
    TheToolTip,
  },

  computed: {
    filteredPlaces() {
      return this.place.filter(item => item.type === 'software');
    }
  },

  data: () => ({
    baseRoute: "software",
    title: "Gerência de Softwares",
    pageIcon: "mdi-monitor-dashboard",

    dialog: false,
    object: {},
    objects: [],
    valid: false,
    error: false,
    edit: false,
    people: [],
    place: [],
    sectors: [],
    search: null,
    tabs: null,

    yesOrNo: [
      { text: "Sim", value: true },
      { text: "Não", value: false },
    ],

    access: [
      { text: "Aplicativo desktop", value: 1 },
      { text: "Aplicativo de celular", value: 2 },
      { text: "Aplicativo desktop e celular", value: 3 },
      { text: "Navegador", value: 4 },
      { text: "Outro", value: 5 },
    ],

    serverTypes: [
      { text: "On-premise", value: 1 },
      { text: "Nuvem", value: 2 },
    ],

    header: [
      { text: "Nome", value: "name", width: "20%" },
      { text: "Fabricante", value: "manufacturer", width: "20%" },
      { text: "Módulos", value: "modules", width: "20%" },
      { text: "Ações", value: "action", width: "10%", align: "center" },
    ],
    
    options: [
      { text: "Comum", value: "Comum" },
      { text: "Sensível", value: "Sensível" },
      { text: "Criança e Adolescente", value: "Criança e Adolescente" },
    ],
    
    types: [
      { text: "Pessoal", value: "Pessoal" },
      { text: "Anonimizado", value: "Anonimizado" },
    ],
   
  }),
  methods: {
    loadSectors() {
      this.$store.dispatch("setLoadingInfo", true)
      const url = `${baseApiUrl}/sector`
      axios
        .get(url)
        .then((res) => {
          this.$store.dispatch("setLoadingInfo", false)
          this.sectors = res.data
        })
        .catch(showError)
    },
    loadPeople() {
      this.$store.dispatch("setLoadingInfo", true)
      const url = `${baseApiUrl}/people`
      axios
        .get(url)
        .then((res) => {
          this.$store.dispatch("setLoadingInfo", false)
          this.people = res.data
        })
        .catch(showError)
    },

    loadPlace() {
      this.$store.dispatch("setLoadingInfo", true);
      const url = `${baseApiUrl}/place`; 
      axios
        .get(url)
        .then((res) => {
          this.$store.dispatch("setLoadingInfo", false);
          this.place = res.data.filter((item) => item.type === "Software")
        })
        .catch(showError);
    },

    loadData() {
      const url = `${baseApiUrl}/${this.baseRoute}`
      this.$store.dispatch("setLoadingInfo", true)
      axios
        .get(url)
        .then((res) => {
          this.$store.dispatch("setLoadingInfo", false)
          this.objects = res.data
        })
        .catch(showError)
    },
    dialogSave() {
      this.object = {}
      this.edit = false
      this.dialog = true
    },
    dialogUpdate(item) {
      this.object = item
      this.edit = true
      this.object.registrationPeople = JSON.parse(
        this.object.registrationPeople
      )
      this.object.queryPeople = JSON.parse(this.object.queryPeople)
      this.object.administratorSector = JSON.parse(
        this.object.administratorSector
      )
      this.object.administratorPeople = JSON.parse(
        this.object.administratorPeople
      )
      this.dialog = true
    },
    resolveForm() {
      this.$refs.form1.validate()
      if (!this.valid) {
        this.$store.dispatch("setErrorInfo", {
          open: true,
          text:
            "Os campos não foram preenchidos corretamente, você pode verificar?",
          button: "Ok!",
        })
        return
      }
      if (this.edit) {
        this.update()
      } else {
        this.save()
      }
    },
    save() {
      this.error = false
      const url = `${baseApiUrl}/${this.baseRoute}`

      const objects = this.object
      objects.registrationPeople = JSON.stringify(objects.registrationPeople)
      objects.queryPeople = JSON.stringify(objects.queryPeople)
      objects.administratorSector = JSON.stringify(objects.administratorSector)
      objects.administratorPeople = JSON.stringify(objects.administratorPeople)

      axios
        .post(url, objects)
        .then(() => this.loadData())
        .catch(showError)
        .finally(() => {
          this.dialog = false
        })
    },
    update() {
      this.error = false
      const id = this.object.id
      const url = `${baseApiUrl}/${this.baseRoute}/${id}`
      const objects = this.object
      objects.registrationPeople = JSON.stringify(objects.registrationPeople)
      objects.queryPeople = JSON.stringify(objects.queryPeople)
      objects.administratorSector = JSON.stringify(objects.administratorSector)
      objects.administratorPeople = JSON.stringify(objects.administratorPeople)
      delete objects.id
      delete objects.createdAt
      delete objects.updatedAt
      delete objects.deletedAt
      axios
        .put(url, objects)
        .then(() => this.loadData())
        .catch(showError)
        .finally(() => {
          this.dialog = false
          this.registrationPeople = []
          this.queryPeople = []
          this.administratorSector = []
          this.administratorSector = []
        })
    },
    remove(id) {
      if (confirm("Remove?")) {
        const url = `${baseApiUrl}/${this.baseRoute}/${id}`
        axios
          .delete(url)
          .then(() => this.loadData())
          .catch(showError)
      }
    },
  },
  mounted() {
    this.loadData()
    this.loadSectors()
    this.loadPeople()
    this.loadPlace();

  },
}
</script>

<style>
.full-height {
  height: 80vh;
}
</style>
